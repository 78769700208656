<template class="body" v-if="routerAlive">
  <div id="app3" class="appclass">
    <Top ref="top" :white="$store.state.model.white"></Top>
    <!-- 主题 -->
    <div v-if="$store.state.islogin">
      <div class="top-body">
        <img width="100%" src="../assets/text/guangg.jpg" v-show="banner_after_guanggao" />
        <transition name="show_jianbian-1">
          <div class="hello" v-show="(enterbanner || isiPad) && !banner_after_guanggao">
            <div class="swiper-container" v-if="banner_list">
              <div class="swiper-wrapper">
                <div
                  class="swiper-slide"
                  v-for="(item, index) in banner_list"
                  :key="index"
                  :data-title="item.title"
                  :data-nodes="item.subtitle"
                  :data-url="item.url"
                >
                  <!-- :style="{ backgroundImage: 'url(' + item.source + ')' }" -->
                  <img width="100%" :src="item.source" alt="首页背景轮播海报大图" />
                  <!-- <img width="100%" :src="item.source" /> -->
                  <!-- <span class="menu-nodes-show">{{ item.nodes }}</span> -->
                </div>
              </div>
              <!-- 如果需要分页器 -->
              <transition name="show_bootom_banner">
                <div
                  v-show="(enterbanner && entermenutotal) || isiPad"
                  class="swiper-pagination"
                  @mousemove="$event.target.click()"
                ></div>
              </transition>
            </div>
          </div>
        </transition>
        <transition name="show_bootom_center">
          <div class="top-content" v-show="(enterbanner && entermenutotal) || isiPad">
            <!-- 主题右侧菜单栏 -->
            <!-- <div class="top-bottom-box"> -->
            <!-- 主题下部组件 -->

            <div class="top-bottom" v-if="banner_list">
              <!-- 左侧频道 -->

              <div class="tp-channel">
                <template v-for="(item, index) in menutotal || menutotalBefore">
                  <div class="tp-chan-box" :key="index" v-if="index < 8 && item.show">
                    <a
                      class="channel-title"
                      :href="
                        YS.returnURL(
                          {
                            path: '/fil',
                            query: { parent_category_id: item.id },
                          },
                          true,
                        )
                      "
                      target="_blank"
                    >
                      <!-- @click="tofil(item.id)" -->
                      <!-- <img src="../assets/img/b1.png" /> -->
                      <img :src="item.icon ? item.icon : iconlistde" alt="大分类图标" />
                      {{ item.name }}
                    </a>
                    ·
                    <template v-for="(value, i) in item.list">
                      <a
                        class="channel-list"
                        v-if="i < 4"
                        @click="tofil(item.id, value.id)"
                        :key="i"
                        :href="
                          YS.returnURL(
                            {
                              path: '/fil',
                              query: {
                                parent_category_id: item.id,
                                category_id: value.id ? value.id : '',
                              },
                            },
                            true,
                          )
                        "
                        target="_blank"
                      >
                        {{ value.name }}
                      </a>
                    </template>
                  </div>
                </template>
              </div>
              <!-- 中间更多 -->
              <!-- <div class="tp-more">
            <div class="more-list" v-for="(item, index) in tpMore" :key="index">
              <a>{{ item.name }}</a>
            </div>
          </div> -->
              <!-- 右侧其他 -->
              <div class="tp-other">
                <div class="tp_other_box" @click="tpo1">
                  <div class="home_other_icon hoi1"></div>
                  <a>午夜版</a>
                </div>
                <!-- <div class="tp_other_box" @click="tpo5">
                <div class="home_other_icon hoi5"></div>
                <a>VIP特权</a>
              </div> -->
                <!-- <div class="tp_other_box" @click="tpo2">
              <div class="home_other_icon hoi2"></div>
              <a>下载APP</a>
            </div> -->
                <!-- <img src="../assets/img/o3.png" /> -->
                <!-- <div class="tp_other_box" @click="tpo3">
                <div class="home_other_icon hoi3"></div>
                <a>广告投放</a>
              </div>
              <div class="tp_other_box" @click="tpo4">
                <div class="home_other_icon hoi4"></div>
                <a>返回旧版</a>
              </div> -->
                <!-- <div class="tp_other_box">
              <img src="../assets/img/logo.png" width="80" />
            </div> -->
              </div>
            </div>
            <!-- </div> -->
          </div>
        </transition>
      </div>

      <!-- 广告暂时写死 -->
      <div
        v-if="guanggaolist && guanggaolist['pc-home-banner-below']"
        class="guanggao"
        :class="{ guanggao_enter: !enterbanner }"
        @click="guanggaocli(guanggaolist['pc-home-banner-below'][0].url)"
        :style="{
          backgroundImage: 'url(' + guanggaolist['pc-home-banner-below'][0].source + ')',
        }"
      ></div>
      <div
        v-if="guanggaolist2 && guanggaolist2['pc-home-banner-below-bottom']"
        class="guanggao_2"
        @click="guanggaocli(guanggaolist2['pc-home-banner-below-bottom'][0].url)"
        :style="{
          backgroundImage: 'url(' + guanggaolist2['pc-home-banner-below-bottom'][0].source + ')',
        }"
      ></div>
      <div class="guanggaolistheight" v-if="!guanggaolist"></div>
      <!-- 推荐/排行榜 -->

      <template v-for="(value, keyi) in menutotalhome">
        <div
          class="nodes-list"
          id="menutotal"
          :key="keyi"
          v-if="
            video_latest_list['i' + value.id] &&
            video_rank_list['i' + value.id] &&
            value.symbol !== 'night'
          "
        >
          <!-- 左侧推荐 -->

          <div class="list-left" v-if="video_latest_list['i' + value.id]">
            <!-- 头部 -->
            <transition name="showleft">
              <div
                class="ll-title"
                v-show="video_latest_list_show[keyi] && video_latest_list_sroll[keyi]"
              >
                <img
                  style="height: 32px; width: 32px"
                  v-if="$refs.top.ub_index == 2"
                  src="../assets/bo/list1.png"
                  alt="图标"
                />
                <img
                  style="height: 32px; width: 32px"
                  v-else
                  src="../assets/img/list1.png"
                  alt="图标"
                />
                <a @click="$refs.top.clitip(value.id, value.pid, keyi)">{{ value.name }}</a>
              </div>
            </transition>
            <transition name="showleft">
              <Latest
                v-show="video_latest_list_show[keyi] && video_latest_list_sroll[keyi]"
                :recLatest="video_latest_list['i' + value.id]"
                :recID="'i' + value.id"
              ></Latest>
            </transition>
            <!-- 左侧主体 -->
          </div>
          <!-- 右侧排行榜 -->
          <div class="list-right" v-if="video_rank_list['i' + value.id]">
            <transition name="showright">
              <div
                class="ll-title"
                v-show="video_latest_list_show[keyi] && video_latest_list_sroll[keyi]"
              >
                <img
                  style="height: 32px; width: 32px"
                  v-if="$refs.top.ub_index == 2"
                  src="../assets/bo/list1.png"
                  alt="图标"
                />
                <img
                  style="height: 32px; width: 32px"
                  v-else
                  src="../assets/img/list1.png"
                  alt="图标"
                />
                <a @click="$refs.top.clitip(value.id, value.pid, keyi)">{{ value.name }}·排行榜</a>
              </div>
            </transition>
            <transition name="showright">
              <Leadranking
                v-show="video_latest_list_show[keyi] && video_latest_list_sroll[keyi]"
                :class="{
                  leadDom: !video_latest_list_show[keyi] || !video_latest_list_sroll[keyi],
                }"
                :leadRanking="video_rank_list['i' + value.id]"
              ></Leadranking>
            </transition>
          </div>
        </div>
      </template>
      <Bottom v-if="(enterbanner && entermenutotal && bottomShow) || isiPad"></Bottom>
      <!-- Swiper -->
    </div>
    <img v-if="!$store.state.islogin" src="../assets/img/sap_b1.png" class="mustlogin-img" />
  </div>
</template>

<script>
let that;
// import { defineComponent } from 'vue'
// import HelloWorld from '@/components/hello-world.vue' // @ is an alias to ..
import Top from '../components/Top'; //导航top
import Bottom from '../components/Bottom'; //导航top
import Leadranking from '../components/Leadranking'; //排行榜
import Latest from '../components/Latest'; //最新 推荐
import 'swiper/dist/css/swiper.min.css';
// import 'swiper/dist/js/swiper.min';
import Swiper from 'swiper';
export default {
  components: {
    Top,
    Bottom,
    Leadranking,
    Latest,
  },
  name: 'Home',
  // computed: {
  //   video_rank_list: function () {
  //     return this.$store.state.video_rank_list;
  //   },
  //   video_latest_list: function () {
  //     return this.$store.state.video_latest_list;
  //   },
  // },
  computed: {
    offLight: function () {
      return this.$store.state.offLight;
    },
    isvip: () => {
      return that.$store.state.isvip;
    },
    // menutotal: () => {
    //   return that.$store.state.menutotal;
    // },
  },
  provide() {
    return {
      routerRefresh: this.routerRefresh,
    };
  },
  data() {
    return {
      hiddenError: false,
      hiddenValue: '', //隐藏密码
      banner_after_guanggao: false,
      isiPad: false,
      entermenutotal: false,
      enterbanner: false,
      bottomShow: false,
      addmouseemterCode: false,
      routerAlive: true,
      sendcode: null,
      enter: false,
      loadCli: false,
      video_rank_list: {},
      video_latest_list: {},
      video_latest_list_show: [
        true,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
      ],
      video_latest_list_sroll: [
        true,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
      ],
      menutotalhome: [], //排行榜和推荐
      menutotal: null,
      banner_list: null,
      clone_banner_list: [],
      guanggaolist: null, //广告信息
      guanggaolist2: null, //广告信息
      // fullHeight: '', //fullHeight: document.documentElement.clientHeight  屏幕高度 默认值
      iconlistde: require('../assets/img/b1.png'),
      iconlist: {
        1: require('../assets/img/b1.png'),
        28: require('../assets/img/b2.png'),
        44: require('../assets/img/b4.png'),
        54: require('../assets/img/b5.png'),
        64: require('../assets/img/b6.png'),
      },
      menutotalBefore: [],
      webwindowScroll: () => {},
    };
  },
  created() {
    that = this;
    that.isiPad = /iPad/i.test(navigator.userAgent);
    // // that.$route.path;
    // // debugger;
    // 检测设备跳转
    //var ismob = /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent);
    if (that.YS.ismob()) {
      //移动端地址
      if (that.$route.path.indexOf('/m.uvod.tv') < 0) {
        // console.log(window.g.MOBILE_URL);
        window.location.href =
          window.g.ub_index == 2 ? window.g.MOBILE_URL_BO : window.g.MOBILE_URLL;
      }
    }
    //获取轮播图缓存

    that.getbanner(); //获取轮播数据，并且初始化轮播器
    //获取广告缓存
    var guanggaostore = JSON.parse(localStorage.getItem('guanggaolist'));
    if (guanggaostore) {
      if (guanggaostore.time > new Date().getTime()) {
        // debugger;
        that.guanggaolist = guanggaostore.data['pc-home-banner-below'];
      }
    }
    //获取广告缓存
    var guanggaostore2 = JSON.parse(localStorage.getItem('guanggaolist2'));
    if (guanggaostore2) {
      if (guanggaostore2.time > new Date().getTime()) {
        that.guanggaolist2 = guanggaostore2.data['pc-home-banner-below-bottom'];
      }
    }
    //获取菜单存储
    var menutotalstore = JSON.parse(localStorage.getItem('menutotal'));
    if (menutotalstore) {
      if (menutotalstore.time > new Date().getTime()) {
        // that.$store.commit('changeEvery', { key: 'menutotal', value: menutotalstore.data });
        that.menutotalhome = menutotalstore.data;
        that.menutotal = menutotalstore.data;
        this.$nextTick(() => {
          that.entermenutotal = true;
        });
      }
    }
  },
  destroyed() {
    window.removeEventListener('scroll', this.webwindowScroll);
    // window.removeEventListener('scroll', this.mouseMoveBanner);
  },
  mounted() {
    this.webwindowScroll = this.YS.fnThrottle(this.windowScroll, 50);
    that.$store.commit('changelogin');
    // alert(document.documentElement.clientHeight);
    // alert(document.documentElement.clientWidth);

    window.addEventListener('scroll', this.webwindowScroll);
    // window.addEventListener('mousemove', this.mouseMoveBanner);
    // window.addEventListener('scroll', this.windowScroll, true);
    setTimeout(() => {
      that.enter = true;
    }, 100);
    setTimeout(() => {
      that.banner_after_guanggao = false;
    }, 5000);
    // that.getRL(); //获取推荐和排行榜

    // that.$store.state.menutotal.forEach((e) => {
    //   that.getRecommend(e.id); //获取最新的电影推荐列表
    //   that.getLeaderboard(e.id); //获取最新的电影排行榜列表
    // });
    document.getElementById('topNav').classList.remove('top-nav-white');
    // var noneMap = document.querySelector('.top-nav-serch')
    // var noneSearch = document.querySelector('.top-nav-input')
    // var noneSearchBody = document.querySelector('.search-body')
    //关闭所有弹窗
    for (let v in this.$store.state.showWindows) {
      //关闭自身外其他弹窗
      this.$store.state.showWindows[v] = false;
    }
    that.adall('pc-home-banner-below');
    that.adall2('pc-home-banner-below-bottom');

    // that.fullHeight = document.documentElement.clientHeight || document.body.clientHeight;
    // that.getElementTop(document.getElementsByClassName('nodes-list')[0]);
  },
  methods: {
    //确认密码进入隐藏
    cliHidden() {},
    //用于图片加载完毕替换图片
    loadOver(pic, i, list) {
      var newImg = new Image();
      newImg.src = pic;
      newImg.onload = () => {
        if (
          that.video_latest_list &&
          that.video_latest_list[list] &&
          that.video_latest_list[list][i]
        ) {
          // return true;
          that.video_latest_list[list][i].pic = newImg.src;
          that.video_latest_list[list][i].picLoad = true;
        }
      };
      // if (!that.$refs[src]) return;
      // let toTop = that.$refs[src].offsetTop;
      // console.log(toTop);
      // let toTop = src
    },
    // changeImg(data) {
    //   that.video_rank_list[data.list][data.index].pic = '';
    // },
    routerRefresh() {
      this.routerAlive = false;
      this.$nextTick(() => {
        this.routerAlive = true;
      });
    },
    //跳转筛选页
    tofil(id, pid) {
      that.YS.routetogo({
        path: '/fil',
        query: { parent_category_id: id, category_id: pid ? pid : '' },
      });
    },
    //获取 最新 的电影/电视剧...列表
    getRecommend(id, i) {
      that.api.user.api_videolatest({ parent_category_id: id }).then((data) => {
        that.$set(this.video_latest_list, 'i' + id, data.video_latest_list);
        // that.video_latest_list_show[i] = true;
        that.$set(this.video_latest_list_show, i, true);
        that.video_latest_list['i' + id].forEach((e, i) => {
          that.loadOver(e.pic, i, 'i' + id);
          that.video_latest_list['i' + id][i].pic = '';
        });
        setTimeout(() => {
          that.bottomShow = true;
        }, 2000);
      });
    },
    //获取 排行榜 的电影/电视剧...列表
    getLeaderboard(id) {
      that.api.user.api_videorank({ parent_category_id: id }).then((data) => {
        that.$set(this.video_rank_list, 'i' + id, data.video_rank_list);
        // that.$store.commit('changeEvery', {
        //   key: 'video_rank_list',
        //   value: data.video_rank_list,
        //   key2: 'i' + id,
        // });
      });
    },
    // 获取在页面上的绝对高度
    getElementTop(element) {
      var actualTop = element.offsetTop;
      var current = element.offsetParent;

      while (current !== null) {
        actualTop += current.offsetTop;
        current = current.offsetParent;
      }
      document.documentElement.clientHeight || document.body.clientHeight;
      return actualTop;
    },

    //获取推荐和排行榜
    getRL() {
      // that.$store.state.menutotal;
      // if (!that.menutotalhome[0]) {
      //   that.api.user.api_categoryhome({}).then((data) => {
      //     that.menutotalhome = [];
      //     data.category_list.forEach((e) => {
      //       if (e.pid == 0) {
      //         e.list = [];
      //         that.menutotalhome.push(e);
      //       }
      //     });
      //     //存储至本地
      //     that.YS.savestore('menutotalhome', that.menutotalhome, 600);
      //     this.$nextTick(() => {
      //       that.getRecommend(that.menutotalhome[0].id); //获取最新的电影推荐列表
      //       that.getLeaderboard(that.menutotalhome[0].id); //获取最新的电影排行榜列表
      //       that.sendcode = 1;
      //     });
      //   });
      // } else {
      //   that.getRecommend(that.menutotalhome[0].id); //获取最新的电影推荐列表
      //   that.getLeaderboard(that.menutotalhome[0].id); //获取最新的电影排行榜列表
      //   that.sendcode = 1;
      // }

      if (!that.menutotal) {
        that.api.user.api_categoryhome({}).then((data) => {
          that.menutotal = [];
          // console.log('获取菜单接口');
          data.category_list &&
            data.category_list.forEach((e) => {
              that.$store.commit('changeEvery', {
                key: 'menudist',
                value: e.name,
                key2: e.id,
              });
              // vuex.menudist[e.id] = e.name;
              if (e.pid == 0) {
                e.list = [];
                // vuex.menutotal.push(e);
                that.menutotal.push(e);
                // that.$store.commit('changeEverypush', { key: 'menutotal', value: e });
              } else {
                that.menutotal.forEach((a, i) => {
                  if (a.id == e.pid) {
                    // a.list.push(e);
                    that.menutotal[i].list.push(e);
                    // that.$store.commit('changeEverypush', {
                    //   key: 'menutotal',
                    //   value: e,
                    //   key2: i,
                    //   key3: 'list',
                    // });
                  }
                });
              }
            });

          that.menutotalhome = that.menutotal;
          this.$nextTick(() => {
            that.entermenutotal = true;
          });

          this.$nextTick(() => {
            that.getRecommend(that.menutotal[0].id), 0; //获取最新的电影推荐列表
            that.getLeaderboard(that.menutotal[0].id, 0); //获取最新的电影排行榜列表
            that.sendcode = 1;
          });
          //存储至本地
          that.YS.savestore('menutotal', that.menutotal, 600);
        });
      } else {
        that.getRecommend(that.menutotal[0].id, 0); //获取最新的电影推荐列表
        that.getLeaderboard(that.menutotal[0].id, 0); //获取最新的电影排行榜列表
        that.sendcode = 1;
      }

      // that.api.user.api_categoryhome({}).then((data) => {
      //   data.category_list.forEach((e) => {
      //     if (e.pid == 0) {
      //       e.list = [];
      //       that.menutotalhome.push(e);
      //     }
      //   });
      //   that.getRecommend(that.menutotalhome[0].id); //获取最新的电影推荐列表
      //   that.getLeaderboard(that.menutotalhome[0].id); //获取最新的电影排行榜列表
      //   that.sendcode = 1;
      //   // //遍历获取全部信息
      //   // that.menutotalhome.forEach((e) => {
      //   //   that.getRecommend(e.id); //获取最新的电影推荐列表
      //   //   that.getLeaderboard(e.id); //获取最新的电影排行榜列表
      //   // });
      // });
    },
    // //获取推荐和排行榜的收藏信息
    // getRLfav() {
    //   // that.$sotre.state.menutotal;
    //   if (that.menutotalhome) {
    //     that.api.user.api_categoryhome({}).then((data) => {
    //       that.menutotalhome = [];
    //       data.category_list.forEach((e) => {
    //         if (e.pid == 0) {
    //           e.list = [];
    //           that.menutotalhome.push(e);
    //         }
    //       });
    //       that.menutotalhome.forEach((e) => {
    //         that.getRecommend(e.id); //获取最新的电影推荐列表
    //         that.getLeaderboard(e.id); //获取最新的电影排行榜列表
    //       });
    //     });
    //   } else {
    //     that.menutotalhome.forEach((e) => {
    //       that.getRecommend(e.id); //获取最新的电影推荐列表
    //       that.getLeaderboard(e.id); //获取最新的电影排行榜列表
    //     });
    //   }
    // },
    // 广告栏点击
    async guanggaocli(url) {
      try {
        window.open(url);
      } catch (error) {
        console.log(error.message);
      }
    },
    // 获取广告
    adall(symbols) {
      if (!that.guanggaolist || !that.guanggaolist[symbols]) {
        that.api.user.api_adall({ symbols: symbols }).then((data) => {
          if (data) {
            if (that.guanggaolist == null) that.guanggaolist = {};
            that.guanggaolist[symbols] = data[symbols];
            //存储至本地
            that.YS.savestore('guanggaolist', that.guanggaolist, 600, symbols);
          }
        });
      }
    },
    // 获取第二条广告
    adall2(symbols) {
      if (!that.guanggaolist2 || !that.guanggaolist2[symbols]) {
        that.api.user.api_adall({ symbols: symbols }).then((data) => {
          if (data) {
            if (that.guanggaolist2 == null) that.guanggaolist2 = {};
            that.guanggaolist2[symbols] = data[symbols];
            //存储至本地
            that.YS.savestore('guanggaolist2', that.guanggaolist2, 600, symbols);
          }
        });
      }
    },
    // 获取轮播图
    getbanner() {
      if (!that.banner_list) {
        // debugger;
        that.api.user.api_banner({ platform: 1 }).then((data) => {
          if (data) {
            that.banner_list = data.banner_list;
            //存储至本地
            that.YS.savestore('banner_list', data.banner_list, 600);
            this.$nextTick(() => {
              that.doswiper();
              that.enterbanner = true;
              // that.setSwiperInit();
            });
          }
        });
      } else {
        var bannerstore = JSON.parse(localStorage.getItem('banner_list'));
        if (bannerstore) {
          if (bannerstore.time > new Date().getTime()) {
            // debugger;
            that.banner_list = bannerstore.data;
            setTimeout(() => {
              that.enterbanner = true;
            }, 50);
            //         this.$nextTick(() => {
            // that.enterbanner = true;
            //         });
          }
        }

        this.$nextTick(() => {
          that.doswiper();
          that.enterbanner = true;
          // that.setSwiperInit();
        });
      }
    },

    // setSwiperInit() {
    //   setTimeout(() => {
    //     debugger;
    //     document.getElementsByClassName('swiper-wrapper')[0].style.transform =
    //       'translate3d(0px, 0px, 0px)';
    //   }, 50);
    // },

    //点击午夜版
    async tpo1() {
      if (that.$store.state.islogin) {
        that.YS.gotoVip({}, 'open', 'hiddenWIN');
      } else {
        that.$refs.top.usepubilDialog({
          title: '该功能需要登录用户才可以体验，请先登录',
          type: 'needlogin',
          code: false,
          failbutton: '立即登录',
        });
      }

      // that.$store.commit('loghtBool');
      // if (that.offLight) {
      //   // document.getElementById('app3').classList.add('app_offLight');
      //   document.getElementsByTagName('body')[0].classList.add('app_offLight');
      // } else {
      //   // document.getElementById('app3').classList.remove('app_offLight');
      //   document.getElementsByTagName('body')[0].classList.remove('app_offLight');
      // }

      // that.$store.commit('getisvipCode');
      // try {
      //   let data = await that.api.user.api_ad({ symbol: 'pc-midnight', result: 1 });
      //   if (data && data.ad) {
      //     window.location.href = data.ad.url;
      //   }

      //   // if (that.isvip) {
      //   //   let data = await that.api.user.api_ad({ symbol: 'pc-midnight', result: 1 });
      //   //   if (data) {
      //   //     window.location.href = data.ad.url;
      //   //   }
      //   // } else {
      //   //   that.$refs.top.usepubilDialog({
      //   //     title: '该功能需要vip才能体验，请先开通vip！',
      //   //     type: 'needvip',
      //   //     code: false,
      //   //     failbutton: '开通vip',
      //   //   });
      //   // }
      // } catch (error) {
      //   console.log(error.message);
      // }
    },
    //点击下载app
    tpo2() {},
    //点击广告投放
    tpo3() {
      that.$refs.top.symbol = 'purchase_vip_card';
      that.$refs.top.customerService();
      that.YS.gotoVip({}, 'open', 'kefugo');
      // if (that.$store.state.islogin) {
      //   that.YS.routetogo({ path: '/help', query: { kefu: 'advertising' } });
      // } else {
      //   //添加登录后的方法队列
      //   that.$store.commit('addloginBefore', () => {
      //     that.YS.routetogo({ path: '/help', query: { kefu: 'advertising' } });
      //   });
      //   that.YS.gotoVip({}, 'open', 'loginReg'); //打开弹窗
      //   that.$refs.top.TOvercode(); //获取验证码
      // }
    },
    //返回旧版
    tpo4() {
      window.location.href = window.g.OLD_URL;
    },
    tpo5() {
      that.YS.routetogo(
        {
          path: '/privileges',
        },
        true,
      );
    },
    // 滚动条距离页面顶部的距离
    windowScroll() {
      // 滚动条距离页面顶部的距离
      // 以下写法原生兼容
      // alert(document.body.clientWidth);
      // alert(document.getElementsByClassName('nodes-list')[0].offsetWidth);
      let scrollTop =
        window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      that.menutotalhome.forEach((e, i) => {
        if (i > 0) {
          if (scrollTop >= 610 * (i - 1)) {
            if (
              (!that.video_latest_list['i' + e.id] || !that.video_rank_list['i' + e.id]) &&
              that.sendcode == i &&
              (that.sendcode += 1)
            ) {
              //过滤show=0
              if (e.show) {
                that.getRecommend(e.id, i); //获取最新的电影推荐列表
                that.getLeaderboard(e.id, i); //获取最新的电影排行榜列表
              }
              // console.log(that.menutotalhome);
            }
          }
        }
      });
      let showDom = document.getElementsByClassName('nodes-list');
      if (showDom.length) {
        showDom.forEach((e, i) => {
          if (that.video_latest_list_sroll[i]) return;
          if (e.offsetTop < document.body.clientHeight + scrollTop) {
            // that.video_latest_list_sroll[i] = true;
            that.$set(that.video_latest_list_sroll, i, true);
          }
        });
      }
    },

    // 初始化/开启轮播器
    doswiper() {
      new Swiper('.swiper-container', {
        loop: true,
        // 如果需要分页器
        pagination: '.swiper-pagination',
        autoplay: 3000,
        speed: 300,
        effect: 'fade',

        // slidesPerView: 'auto',
        observer: true,
        observeParents: true,
        // paginationType: 'custom',
        paginationBulletRender: function (swiper, index, className) {
          if (document.querySelectorAll('.swiper-slide')[index]) {
            document.querySelectorAll('.swiper-slide');
            var title = document
              .querySelectorAll('.swiper-slide')
              [index + 1].getAttribute('data-title');
            var nodes = document
              .querySelectorAll('.swiper-slide')
              [index + 1].getAttribute('data-nodes');
            return '<span class="' + className + '">' + title + '<p>' + nodes + '</p></span>';
          }
        },
        paginationClickable: true,
        // 如果需要前进后退按钮
        // nextButton: '.swiper-button-next',
        // prevButton: '.swiper-button-prev',
        // 如果需要滚动条
        // scrollbar: '.swiper-scrollbar',
      });
      var swiperslide = document.getElementsByClassName('swiper-slide');
      if (swiperslide) {
        swiperslide.forEach((item) => {
          item.addEventListener('click', () => {
            var url = item.getAttribute('data-url');
            window.open(url);
          });
        });
      }
      // setTimeout(() => {
      //   var swiperbutton = document.getElementsByClassName('swiper-pagination-bullet');
      //   if (swiperbutton) {
      //     swiperbutton.forEach((item) => {
      //       item.addEventListener('mouseenter', () => {
      //         // console.log(item);
      //         item.click();
      //       });
      //     });
      //     // swiperbutton[0].click();
      //   }
      // }, 1000);
    },
    // //添加鼠标放上去自动点击
    // addmouseemter() {
    //   if (that.addmouseemterCode) return;
    //   setTimeout(() => {
    //     var swiperbutton = document.getElementsByClassName('swiper-pagination-bullet');
    //     if (swiperbutton) {
    //       swiperbutton.forEach((item) => {
    //         item.addEventListener('mouseenter', () => {
    //           item.click();
    //         });
    //       });
    //     }
    //     that.addmouseemterCode = true;
    //   }, 2000);
    // },
  },
};
</script>

<style lang="scss">
.mustlogin-img {
  position: fixed;
  -webkit-filter: blur(50px);
  filter: blur(50px);
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  // -webkit-transform: translate(-50%, -50%) scale(1.5);
  // transform: translate(-50%, -77%) scale(1.2);
  z-index: -1;
  opacity: 0.2;
  transform: rotate(180deg);
}
html,
body {
  position: relative;
  height: 100%;
}
.hello {
  // max-height: 580px;
  // position: absolute;
  width: 100%;

  // overflow: hidden;
  position: relative;
  transform: 0.5s;
}

.hello .swiper-container {
  width: 100%;
  max-width: 1903px;
  max-height: 35vw;
  // min-height: ;
  // height: 100%;
  // height: 580px;
  // height: 666px;
  // img {
  //   min-height: 580px;
  // }
}

.hello .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  cursor: pointer;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;

  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  // margin-bottom: 50px;
  // width: 100% !important;
  // height: 580px;
  position: relative;
  color: #fff;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  img {
    width: 100%;
  }
}

.hello .swiper-pagination-bullet {
  transition: all 0.3s;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  font-size: 12px;
  color: #000;
  opacity: 1;
  background: rgba(0, 0, 0, 0);
  cursor: pointer;
}

.hello .swiper-pagination {
  // flex-direction: column;
  // justify-content: center;
  // font-style: italic;
  // font-weight: 300;
  // font-size: 18px;
  // z-index: 1;
  // // background: yellow;
  // display: flex;
  // // height: 400px;
  // width: 240px !important;
  // background: linear-gradient(
  //   136deg,
  //   rgba(0, 0, 0, 0) 0%,
  //   rgba(0, 0, 0, 0.3) 33%,
  //   rgba(0, 0, 0, 0.5) 50%,
  //   rgba(0, 0, 0, 0.8) 66%,
  //   black 100%
  // );
  // opacity: 1;
  // position: absolute;
  // // border-radius: 12px;
  // overflow: hidden;
  // // left: 50% !important;
  // // margin-left: 535px;
  // // bottom: 46px !important;
  // left: 100% !important;
  // margin-left: -21% !important;
  // bottom: 55px !important;
  // // top: 9% !important;
  // span {
  //   margin: 0 !important;
  //   width: auto;
  //   font-size: 16px;
  //   font-family: PingFang SC;
  //   font-weight: 400;
  //   line-height: 22px;
  //   color: #ffffff;
  //   opacity: 1;
  //   height: 22px;
  //   padding: 9px 20px;
  //   display: flex;
  //   overflow: hidden;
  //   border-radius: 0 !important;
  //   line-height: 30px;
  //   flex-direction: column;
  //   align-items: flex-start;
  //   white-space: nowrap;
  //   font-style: initial;

  //   p {
  //     width: 100px;
  //     height: 17px;
  //     font-size: 12px;
  //     font-family: PingFang SC;
  //     font-weight: 300;
  //     line-height: 17px;
  //     color: #ffffff;
  //     opacity: 1;
  //     font-style: initial;
  //     margin-top: 4px;
  //     display: flex;
  //   }
  // }
  flex-direction: column;
  justify-content: center;
  font-style: italic;
  font-weight: 300;
  font-size: 15px;
  z-index: 1;
  // background: yellow;
  display: flex;
  // height: 400px;
  width: 23% !important;
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.3) 33%,
    rgba(0, 0, 0, 0.5) 50%,
    rgba(0, 0, 0, 0.6) 66%,
    rgba(0, 0, 0, 0.7) 100%
  );
  // background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.63) 100%);
  // background: linear-gradient(
  //   -90deg,
  //   rgba(9, 11, 33, 0.5),
  //   rgba(9, 11, 33, 0.05) 83%,
  //   rgba(9, 11, 33, 0.02) 89%,
  //   rgba(9, 11, 33, 0) 99%
  // );
  opacity: 1;
  position: absolute;
  // border-radius: 12px;
  overflow: hidden;
  // left: 50% !important;
  // margin-left: 535px;
  // bottom: 46px !important;
  left: 100% !important;
  margin-left: -23% !important;
  // bottom: 55px !important;
  // bottom: 95px !important;
  bottom: 0px !important;
  padding-bottom: 32px;
  // height: calc(100% - 155px);
  height: calc(100% - 32px);
  // top: 9% !important;
  span {
    font-weight: 400;
    margin: 0 !important;
    width: 55%;
    font-size: 15px;
    line-height: 22px;
    // color: #ffffff;
    color: hsla(0, 0%, 100%, 0.7);
    opacity: 1;
    // height: 22px;
    height: 7.5%;
    padding: 0 20px;
    display: flex;
    overflow: hidden;
    border-radius: 0 !important;
    line-height: 40px;
    flex-direction: column;
    // align-items: flex-start;
    align-items: flex-end;
    white-space: nowrap;
    font-style: initial;

    p {
      display: none !important;
      // width: 100px;
      height: 17px;
      font-size: 12px;
      font-family: PingFang SC;
      font-weight: 300;
      line-height: 17px;
      color: #ffffff;
      opacity: 1;
      font-style: initial;
      margin-top: 4px;
      display: flex;
    }
  }
}
.hello .swiper-pagination-bullet-active {
  font-weight: 600 !important;
  transition: all 0.3s;
  border-radius: 8px !important;
  color: #fff !important;
  width: 180px;
  display: flex;
  flex-direction: column;
  font-size: 22px !important;
  justify-content: center !important;
}
// .top-nav {
//   background: rgba(0, 0, 0, 0.6);
//   .tag-input {
//     background: #ffffff;
//     // border: 1px solid rgba(255, 255, 255, 0.43137254901960786);
//   }
//   .tag-span,
//   .right-span {
//     color: white;
//   }
//   .bubble {
//     background: #91d6ff;
//   }
//   .map_icon {
//     background-image: url(../assets/img/down2.png);
//   }
//   .ri_3 {
//     background-image: url(../assets/img/recording4.png);
//   }
//   .ri_4 {
//     background-image: url(../assets/img/notice4.png);
//   }
// }
.hello .swiper-slide img {
  // width: 1903px;
  width: 100%;
}
html {
  overflow-y: scroll !important;
}
</style>
<style scoped>
/* .top-nav {
  width: 100%;
  height: 60px;
  background: rgba(0, 0, 0, 0.6) !important;
  position: fixed;
  z-index: 100;
  box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
} */
</style>
